import React, {useRef, useEffect} from 'react'

const useEventListener = (target, type, ...options) => {
  const handleListener = (ref) => ref.classList.remove('isLoading');
  
  useEffect(
    () => {
      const targetIsRef = target.hasOwnProperty("current");
      const currentTarget = targetIsRef ? target.current : target;
      if (currentTarget)
        currentTarget.querySelector('video').addEventListener(type, () => handleListener(currentTarget), ...options);
      return () => {
        if (currentTarget)
          currentTarget.querySelector('video').removeEventListener(type, () => handleListener(currentTarget), ...options);
      };
    },
    [target, type, handleListener, options]
  );
};

const Video = ({src, caption = "", className = "", muted = true, loop = true, autoPlay = true, playsInline = true}) => {
  const ref = useRef();

  useEventListener(ref, 'canplay');
  
  return (
    <div
      ref={ref}
      className={`video video--mp4 isLoading`}
      dangerouslySetInnerHTML={{
        __html: `
          <figure>
            <video class="${className}" loop=${loop} autoPlay=${autoPlay} muted=${muted} playsInline=${playsInline} >
              <source src=${src} type="video/mp4"/>
            </video>
            ${caption !== '' ? `<figcaption class="gatsby-resp-image-figcaption">${caption}</figcaption>` : ''}
          </figure>
        `
    }} />
  )
}

export default Video;