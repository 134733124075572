import React from "react";
import { MDXProvider } from "@mdx-js/react";

import Header from "./Header";
import Button from "./Button";
import Video from "./Video";
import VimeoPlayer from "./VimeoPlayer";
import Icon from "../components/Icon";
import "../styles/styles.css";
// import {useCurrentBreakpoint} from "../hooks/useCurrentBreakpoint"
import { myContext } from "../context/GlobalContextProvider";

const shortcodes = {
  Button,
  Icon,
  Video,
  VimeoPlayer,
};

const Layout = (props) => {
  const { render, location, className } = props;
  // const isHome = location.pathname === "/";
  // const breakpoint = useCurrentBreakpoint();
  // const { show, toggle, content } = useSingleModal();

  return (
    <myContext.Consumer>
      {(context) => {
        if (context.user == null) return;
        return (
          <>
            <div className="overflow-x--hidden">
              <Header context={context} />
              <MDXProvider components={shortcodes}>
                <main className={`${className}`}>
                  {render(context)}
                  <footer className="flex space-between padding-6 md_padding-12 lg_padding-16">
                    <div>
                      <p>
                        Built by{" "}
                        <a
                          href="http://goodthings.io"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Goodthings
                        </a>
                      </p>
                    </div>
                  </footer>
                </main>
              </MDXProvider>
            </div>
          </>
        );
      }}
    </myContext.Consumer>
  );
};

export default Layout;
