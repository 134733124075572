import React from "react";

const getButtonTheme = (value) => {
  let result;
  switch (value) {
    case "dark":
      result = "button--dark";
      break;

    default:
      result = "";
  }
  return result;
};

const Button = ({
  onClick,
  disabled,
  className,
  children,
  theme,
  type = "button",
}) => {
  return (
    <button
      className={`button ${className ? className : ""} ${getButtonTheme(
        theme
      )}`}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
