export const minMax = (items) => [Math.min(...items), Math.max(...items)];

export const getErrorMessage = (code) => {
  console.log(code);
  let message;
  switch (code) {
    case "auth/user-not-found":
      message = "Email or password is incorrect";
      break;
    case "auth/wrong-password":
      message = "Email or password is incorrect";
      break;
    case "card_declined":
      message = "Your card was declined. Please use another card.";
      break;
    case "card/empty":
      message = "Please add your credit card info";
      break;
    case "auth/email-already-in-use":
      message = "The email address is already in use";
      break;
    case "setup_intent_authentication_failure":
      message =
        "We are unable to authenticate your payment method. Please choose a different payment method and try again.";
      break;

    default:
      message = "An error has occured. Please try again";
      break;
  }
  return message;
};
