import React, { useState } from "react";

import { sendPasswordReset } from "../utils/firebase-utils";

export default function PasswordResetForm({ onSuccess }) {
  const initData = {
    email: "",
  };
  const [formData, setFormData] = useState(initData);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const { error } = sendPasswordReset(formData.email);
    if (error) alert(error);
    if (onSuccess) onSuccess();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <h1 className="headline2 marginBottom-8">Enter your student email.</h1>
      <p className="marginBottom-8">
        If student account exists with this email address, we'll send you instructions to
        reset.
      </p>
      <label htmlFor="email">Email</label>
      <input
        className="input-text"
        type="email"
        name="email"
        id="email"
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        value={formData.email}
      />
      <button className="button marginTop-8" type="submit">
        Reset
      </button>
    </form>
  );
}
