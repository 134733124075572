import React, {useState} from 'react'
import Vimeo from '@u-wave/react-vimeo';

const VimeoPlayer = ({id, loop, caption}) => {
  const [isLoading, setIsLoading] = useState(true);
  const loopValue = loop === "true" ? true : false

  return (
    <div className={`video video--vimeo ${isLoading ? 'isLoading' : ''}`}>
      <figure>
        <Vimeo
          className="mediaPlayer"
          onReady={() => setIsLoading(false)}
          video={id} 
          loop={loopValue}
          paused={isLoading}
          autoplay={loopValue}
          muted={loopValue}
          showPortrait={false} 
          showTitle={false} 
          controls={false}
          responsive={true}
        />
        {caption && <figcaption className="gatsby-resp-image-figcaption">{caption}</figcaption>}
      </figure>
    </div>
  )
}

export default VimeoPlayer;