import firebase from "gatsby-plugin-firebase";

export const createUser = async (userData) => {
  return await firebase
    .auth()
    .createUserWithEmailAndPassword(userData.email, userData.password)
    .then(async (res) => {
      console.log("user created:", res);
      const user = res.user;
      userData.uid = user.uid;
      await createUserData(userData);
      const userWithStripe = await firebase
        .functions()
        .httpsCallable("createStripeCustomer")(userData);
      return { user: { ...userWithStripe.data, uid: user.uid } };
    })
    .catch(function(error) {
      console.log(error.code, error.message);
      return { error: error };
    });
};

export const createBatchUsers = (users) => {
  if (users && users.length) {
    return users.forEach(async (user) => {
      user.password = "willchange";
      await firebase
      .functions()
      .httpsCallable("createFreeUser")(user)
      .then(async (res) => {
        console.log("user created:", res.data);
        user.uid = res.data.uid;
        user.hasEnrolled = true;
        await createUserData(user);
        console.log(`created userData: ${user.email}`);
        await firebase.functions().httpsCallable("createStripeCustomer")(user);
        console.log(`created stripe customer: ${user.email}`);
        await sendPasswordReset(user.email);
      })
      .catch((error) => {
        console.warn('failed creating user: ', user.email);
        console.error(error)
      });
    });
  } else {
    return console.error("batchCreateUsers failed");
  }
};

export const setStripeIntent = async (uid, intent) => {
  await firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("payment_methods")
    .add({ id: intent.payment_method });
};

export const createStripeIntent = async (uid, customer_id) => {
  return await firebase.functions().httpsCallable("createStripeIntent")({
    uid,
    customer_id,
  });
};

export const deleteCurrentUser = () => {
  var user = firebase.auth().currentUser;

  user
    .delete()
    .then(function() {
      console.log("user deleted");
    })
    .catch(function(error) {
      console.error(error);
    });
};

export const createUserData = (user) => {
  const { firstName, lastName, email, uid, hasEnrolled } = user;
  const studentId = `${lastName.slice(0, 4).toUpperCase()}${new Date()
    .getTime()
    .toString()
    .slice(-4)}`;
  return firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .set({
      purchases: [],
      tags: [],
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
      email,
      studentId,
      hasEnrolled: hasEnrolled ? hasEnrolled : false,
    })
    .then(() => {
      console.log("added user data");
    });
};

export const ammendCurrentUserData = async (data) => {
  firebase
    .firestore()
    .collection("users")
    .doc(await getCurrentUserUID())
    .set(data, { merge: true })
    .then(() => {
      console.log("ammended user data");
    });
};

export const setUserProfile = (user, cb) => {
  const { firstName, lastName, email, uid } = user;
  return firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .set({
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
      email,
    })
    .then(() => {
      if (cb) cb();
      console.log("added user data");
    });
};

export const getCurrentUserUID = () => {
  return firebase.auth().currentUser.uid;
};

export const checkCurrentUser = () => {
  return firebase.auth().currentUser;
};

const sendEventWelcomeEmail = async ({ userUID, eventUID }) => {
  const sendEmail = firebase.functions().httpsCallable("sendEventWelcomeEmail");

  const data = {
    user: await getUserData(userUID),
    event: await getEventByUID(eventUID),
  };

  sendEmail(data)
    .then((result) => {
      console.log("sendEmail result:", result);
    })
    .catch((error) => {
      var code = error.code;
      var message = error.message;
      var details = error.details;
      console.warn("error sendEventWelcomeEmail", code, message, details);
    });
};

export const getUsersByEvent = (uid) => {
  return firebase
    .firestore()
    .collection("usersByEvent")
    .doc(uid)
    .collection('attendees')
    .get()
    .then((snapshot) => {
      const docs = []
      snapshot.forEach((doc) => {
        docs.push(doc.data());
      })
      return docs;
    })
    .catch((error) => {
      console.error("Error getting usersByEvent", error);
    });
};

export const getAllUsers = () => {
  return firebase
    .firestore()
    .collection("users")
    .get()
    .then((snapshot) => {
      const docs = []
      snapshot.forEach((doc) => {
        docs.push(doc.data());
      })
      console.log(docs);
      return docs;
    })
    .catch((error) => {
      console.error("Error getting usersByEvent", error);
    });
};

export const decrementInventory = (collection, eventUID, items) => {
  // !FIX - do all at once?
  Object.keys(items).forEach(async (key) => {
    const increment = firebase.firestore.FieldValue.increment(
      items[key].quantity
    );
    await firebase
      .firestore()
      .collection(collection)
      .doc(eventUID)
      .update({ ["purchases." + key]: increment })
      .then((result) => console.log("inventory updated", result));
  });
};

export const addUserToEvent = async (
  userData,
  userUID,
  eventUID,
  hasPurchase
) => {
  const { studentId, email, firstName, lastName } = userData;

  if (hasPurchase) {
    firebase
      .firestore()
      .collection("usersByEvent")
      .doc(eventUID)
      .collection("registrants")
      .doc(userUID)
      .set({
        email,
        firstName,
        lastName,
        studentId,
      });
  }

  firebase
    .firestore()
    .collection("usersByEvent")
    .doc(eventUID)
    .collection("attendees")
    .doc(userUID)
    .set({
      email,
      firstName,
      lastName,
      studentId,
    });

  firebase
    .firestore()
    .collection("users")
    .doc(userUID)
    .update({
      events: firebase.firestore.FieldValue.arrayUnion(eventUID),
    })
    .then(() => {
      console.log("added event to user");
      sendEventWelcomeEmail({ userUID, eventUID });
    });
};

export const removeUserFromEvent = async (eventUID) => {
  const userUID = await getCurrentUserUID();

  firebase
    .firestore()
    .collection("usersByEvent")
    .doc(eventUID)
    .collection("attendees")
    .doc(userUID)
    .delete()
    .then((result) => console.log("user removed from usersByEvent", result));

  firebase
    .firestore()
    .collection("users")
    .doc(userUID)
    .update({
      events: firebase.firestore.FieldValue.arrayRemove(eventUID),
    })
    .then(() => {
      console.log("removed event from user");
    });
};

export const getUserData = async (uid) => {
  if (!uid) uid = await getCurrentUserUID();
  const userRef = firebase
    .firestore()
    .collection("users")
    .doc(uid);

  return userRef
    .get()
    .then(function(doc) {
      if (doc.exists) {
        return doc.data();
      } else {
        console.log("No such document!");
      }
    })
    .catch(function(error) {
      console.log("Error getting document:", error);
    });
};

export const getEventByUID = (uid) => {
  const eventRef = firebase
    .firestore()
    .collection("events")
    .doc(uid);

  return eventRef
    .get()
    .then(function(doc) {
      if (doc.exists) {
        return doc.data();
      } else {
        console.log("No such document!");
      }
    })
    .catch(function(error) {
      console.log("Error getting document:", error);
    });
};

export const signOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      console.log("user signed out");
      window.scrollTo(0, 0);
    })
    .catch((error) => console.log("error while signing out", error));
};

export const login = (data) => {
  const { email, password } = data;
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => {
      console.log("user signed in");
      window.scrollTo(0, 0);
      return { error: false };
    })
    .catch(function(error) {
      return { error };
    });
};

export const submitPayment = async (customerData, paymentData) => {
  return await firebase
    .firestore()
    .collection("users")
    .doc(customerData.uid)
    .collection("purchases")
    .add({ ...paymentData })
    .then(async (docRef) => {
      const paymentResult = await firebase
        .functions()
        .httpsCallable("createStripePayment")({
        uid: customerData.uid,
        itemUID: customerData.itemUID,
        idempotencyKey: docRef.id,
        customer: customerData.customer_id,
        ...paymentData,
      });
      if (paymentResult.error) {
        return paymentResult;
      } else {
        await addItemPurchaseToCurrentUser(customerData.itemUID);
        return paymentResult;
      }
    });
};

export const getCurrentUserPaymentMethods = async (uid) => {
  if (!uid) uid = await getCurrentUserUID();

  return await firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("payment_methods")
    .get()
    .then((snapshot) => {
      return snapshot.docs.map((doc) => doc.data());
    });
};

export const addUserPaymentMethod = (uid, payment_method) => {
  return firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("payment_methods")
    .add({ id: payment_method })
    .then((docRef) => {
      return docRef.get().then((doc) => doc.data());
    });
};

export const sendPasswordReset = (email) => {
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(function() {
      return { success: true };
    })
    .catch(function(error) {
      return { error };
    });
};

export const addItemPurchaseToCurrentUser = async (item) => {
  if (!item) return;
  firebase
    .firestore()
    .collection("users")
    .doc(await getCurrentUserUID())
    .update({
      purchases: firebase.firestore.FieldValue.arrayUnion(item),
    })
    .then(() => {
      console.log("added item purchase to user");
    });
};

export const addNotifyTagsToCurrentUser = async (event) => {
  firebase
    .firestore()
    .collection("users")
    .doc(await getCurrentUserUID())
    .update({
      tags: firebase.firestore.FieldValue.arrayUnion({
        title: event.title,
        priceId: event.priceId,
      }),
    })
    .then(() => {
      console.log("added tag to user");
    });
};

export const addUserToWaitList = async (user, event) => {
  try {
    const result = await firebase
      .functions()
      .httpsCallable("addUserToMailchimp")({
      user,
      event: event.title,
    });
    await addNotifyTagsToCurrentUser(event);
    return result;
  } catch (error) {
    return error;
  }
};
