import React from "react";
import Button from "../components/Button";
import Login from "../components/Login";
import Icon from "../components/Icon";
import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import { Link } from "gatsby";
import { signOut } from "../utils/firebase-utils";
import useSnackBars from "../hooks/useSnackBars";

const Header = ({ className, context }) => {
  const { addAlert } = useSnackBars();
  const [showModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Modal open={open} close={hideModal} onExited={onExited}>
      <div className="modal--sm">
        <Login
          onSuccess={(data) => {
            addAlert("Welcome back. 👋");
            hideModal();
          }}
          onCancel={hideModal}
        />
      </div>
    </Modal>
  ));

  return (
    <header
      className={`
        ${className}
      `}
    >
      <div className="header-wrap">
        <div className="header-contents">
          <Link to="/">
            <Icon name="logo" />
          </Link>
          <div className="sign-in">
            {!context.user ? (
              <Button className="button--textonly" onClick={showModal}>
                Sign in
              </Button>
            ) : (
              <Button
                className="button--textonly"
                onClick={() => {
                  addAlert("👋 You've been signed out.");
                  signOut();
                }}
              >
                Sign out
              </Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
