import React, { useState } from "react";

import PasswordResetForm from "./PasswordResetForm";
import Button from "./Button";
import { login, getUserData } from "../utils/firebase-utils";
import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import { getErrorMessage } from "../utils/helpers";

const Login = ({ onSuccess, onCancel }) => {
  const initData = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Modal open={open} close={hideModal} onExited={onExited}>
      <div className="modal--sm">
        <PasswordResetForm onSuccess={hideModal} />
      </div>
    </Modal>
  ));

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (hasError) setHasError(false);
        setIsSubmitting(true);
        const { error } = await login(formData);
        if (error) {
          setIsSubmitting(false);
          return setHasError(getErrorMessage(error.code));
        }
        const user = await getUserData();
        if (onSuccess) onSuccess(user);
      }}
    >
      <div className="marginBottom-6">
        <h1 className="headline2 marginBottom-6">Sign in</h1>
        <label htmlFor="email">Email</label>
        <input
          className="input-text"
          type="email"
          name="email"
          id="email"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
        />
      </div>
      <div className="marginBottom-6">
        <label htmlFor="password">Password</label>
        <input
          className="input-text"
          type="password"
          name="password"
          id="password"
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
          value={formData.password}
        />
      </div>
      {hasError && <p className="c-red bold marginBottom-4">{hasError}</p>}
      <div className="marginBottom-6">
        <button
          disabled={isSubmitting}
          className={`button ${isSubmitting ? "button--loading" : ""}`}
          type="submit"
        >
          {isSubmitting ? <span>Logging in...</span> : "Sign in"}
        </button>
      </div>
      <div>
        <Button className="button--textonly c-black" onClick={showModal}>
          Forgot your password?
        </Button>
      </div>
    </form>
  );
};

export default Login;
