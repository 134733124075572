
import React, { Fragment } from "react";

const Instagram = ({
  width = 40,
  height = 40,
  className,
  style,
  viewBox = "3 4 60 60",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.271 26.578v-.006c.502 0 1.005.01 1.508-.002.646-.016 1.172-.57 1.172-1.217v-2.89c0-.691-.547-1.24-1.236-1.241h-2.883a1.234 1.234 0 00-1.236 1.243c-.001.955-.004 1.91.003 2.865a1.4 1.4 0 00.073.426c.173.508.639.82 1.209.823l1.39-.001zM33 27.817a6.181 6.181 0 00-.142 12.361c3.454.074 6.248-2.686 6.321-6.043.075-3.46-2.717-6.32-6.179-6.318zm-11.954 3.299v13.627c0 .649.562 1.208 1.212 1.208h21.479c.656 0 1.215-.557 1.215-1.212V31.117H42.04a9.545 9.545 0 01.376 4.014c-.161 1.363-.601 2.631-1.316 3.803s-1.644 2.145-2.779 2.918a9.482 9.482 0 01-9.946.428c-1.579-.885-2.819-2.12-3.685-3.713-1.289-2.373-1.495-4.865-.739-7.451h-2.905zm24.159 18.139c.159-.026.318-.049.475-.083 1.246-.265 2.264-1.304 2.508-2.557.025-.137.045-.273.067-.409V21.794c-.021-.133-.04-.268-.065-.401a3.301 3.301 0 00-2.78-2.618c-.058-.007-.113-.02-.17-.03H20.761c-.147.027-.296.047-.441.08a3.29 3.29 0 00-2.545 2.766c-.008.057-.02.114-.029.171V46.24c.028.154.05.311.085.465a3.302 3.302 0 002.77 2.52c.064.008.13.021.195.03h24.409zM33 64C16.432 64 3 50.568 3 34 3 17.431 16.432 4 33 4s30 13.431 30 30c0 16.568-13.432 30-30 30z"
      clipRule="evenodd"
    ></path>
  </svg>
);

const Box = ({
  width = 20,
  height = 22,
  className,
  style,
  viewBox = "0 0 20 22",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.022 0c.09.003.172.02.24.044l9.302 3.72A.716.716 0 0120 4.42v12.558c0 .276-.18.543-.436.647l-9.302 3.72a.706.706 0 01-.524 0l-9.302-3.72A.715.715 0 010 16.977V4.42a.716.716 0 01.436-.654L9.738.044A.76.76 0 0110.022 0zM10 1.447L2.587 4.412 5.35 5.524l7.42-2.973L10 1.447zm4.651 1.86L7.238 6.272 10 7.384l7.413-2.972-2.762-1.105zm3.954 2.144l-7.907 3.161v11.054l7.907-3.169V5.451zm-17.21 0v11.046l3.256 1.301v-2.681a.698.698 0 111.396 0v3.24l3.255 1.309V8.612l-3.255-1.3V10a.698.698 0 11-1.396 0V6.752L1.395 5.45z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const Ticket = ({
  width = 22,
  height = 21,
  className,
  style,
  viewBox = "0 0 22 21",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65065193,0.943905909 L21.9846655,14.2779195 L19.1794222,17.0831628 L18.8531043,16.757841 C18.5308579,16.4505373 18.1998942,16.215207 17.6718289,16.2131248 C16.839726,16.2098437 16.1384151,16.9111545 16.1416962,17.7432575 C16.1444725,18.4473446 16.5619121,18.8010286 17.0117342,19.2508508 L14.2064909,22.0560941 L0.872477338,8.7220805 L3.67772063,5.91683721 L3.99952026,6.23764065 C4.3142362,6.53741389 4.63315101,6.76069541 5.18531395,6.78687523 C6.01741688,6.79015628 6.71872771,6.08884546 6.71544666,5.25674252 C6.68054023,4.52052527 6.29523078,4.19897135 5.84540864,3.74914921 L8.65065193,0.943905909 Z M12.8224773,7.23690591 L7.16547734,12.8939059 L14.2063899,19.9346727 L14.982086,19.1589766 L14.930249,19.0662141 C14.7586183,18.7354238 14.6680359,18.3831764 14.6466642,17.9432655 L14.6417079,17.7491721 C14.635142,16.0840251 16.0125965,14.7065706 17.6777435,14.7131364 C18.2106678,14.7152378 18.6167395,14.8055282 18.9947855,15.0016775 L19.0882551,15.0528075 L19.8632442,14.2778185 L12.8224773,7.23690591 Z M13,10.5 C14.3807119,10.5 15.5,11.6192881 15.5,13 C15.5,14.3807119 14.3807119,15.5 13,15.5 C11.6192881,15.5 10.5,14.3807119 10.5,13 C10.5,11.6192881 11.6192881,10.5 13,10.5 Z M13,12 C12.4477153,12 12,12.4477153 12,13 C12,13.5522847 12.4477153,14 13,14 C13.5522847,14 14,13.5522847 14,13 C14,12.4477153 13.5522847,12 13,12 Z M8.65065193,3.06522625 L7.86434919,3.85152899 L7.91493119,3.95358094 L7.98337409,4.11410945 L8.04751354,4.28899941 C8.14386877,4.5710978 8.19875562,4.86916908 8.215435,5.25082794 C8.22200081,6.91597489 6.84454632,8.29342938 5.11427393,8.28519206 C4.79774051,8.27018419 4.49966923,8.21529734 4.21757084,8.11894211 C4.09544584,8.07722835 3.98512877,8.03381146 3.88215237,7.98635977 L3.78080753,7.93507066 L2.99379768,8.7220805 L6.10547734,11.8339059 L11.7624773,6.17690591 L8.65065193,3.06522625 Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const Facebook = ({
  width = 40,
  height = 40,
  className,
  style,
  viewBox = "0 0 48 48",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="currentColor"></circle>
    <path
      fill="#000"
      d="M29.9 19.5h-4v-2.6c0-1 .7-1.2 1.1-1.2h2.8v-4.4h-3.9c-4.4 0-5.3 3.3-5.3 5.3v2.9h-2.5V24h2.5v12.7h5.3V24h3.6l.4-4.5z"
    ></path>
  </svg>
);

const Logo = ({
  width = 40,
  height = 45.12,
  className,
  style,
  viewBox = "0 0 40 45.12",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M38.43,17.69l1.49-2.28a.49.49,0,0,0-.15-.68c-4.24-2.6-10.48-4.09-17.21-4.09a43.74,43.74,0,0,0-6.46.47c1.63-4.63,4.12-7.42,6.46-7.42,1.78,0,3.64,1.6,5.15,4.4a.48.48,0,0,0,.64.21l2.43-1.22A.49.49,0,0,0,31,6.42C28.8,2.27,25.81,0,22.56,0,19.12,0,16,2.52,13.77,7.11A28.44,28.44,0,0,0,12,12a28.44,28.44,0,0,0-4.87,1.79C2.52,16,0,19.12,0,22.56s2.52,6.56,7.11,8.79A28.44,28.44,0,0,0,12,33.14,28.87,28.87,0,0,0,13.77,38c2.23,4.58,5.35,7.1,8.79,7.1,3.23,0,6.2-2.24,8.39-6.33a.48.48,0,0,0-.21-.66L28.32,36.9a.49.49,0,0,0-.65.2c-1.5,2.76-3.34,4.33-5.11,4.33s-3.91-1.83-5.47-5a24.83,24.83,0,0,1-1-2.39,43.73,43.73,0,0,0,6.46.48c6.67,0,12.88-1.47,17.12-4a.5.5,0,0,0,.15-.68l-1.47-2.29a.5.5,0,0,0-.67-.15c-3.63,2.18-9.24,3.47-15.13,3.47A38.63,38.63,0,0,1,15,30.08a38.55,38.55,0,0,1-.71-7.52A38.66,38.66,0,0,1,15,15a38.66,38.66,0,0,1,7.52-.71c5.94,0,11.58,1.3,15.21,3.5A.48.48,0,0,0,38.43,17.69ZM11.11,29c-4.63-1.63-7.42-4.12-7.42-6.46s2.79-4.83,7.42-6.46a44.63,44.63,0,0,0,0,12.92Z"
    />
    <path
      fill="currentColor"
      d="M21.16,19.9a3.06,3.06,0,0,1,3.66.57.5.5,0,0,0,.65.07l2.21-1.6a.49.49,0,0,0,.09-.71,6.75,6.75,0,1,0-5.18,11.12,6.65,6.65,0,0,0,5.07-2.27.49.49,0,0,0-.06-.71l-2.18-1.65a.49.49,0,0,0-.64.05,3.07,3.07,0,1,1-3.62-4.87Z"
    />
  </svg>
);

const Email = ({
  width = 40,
  height = 40,
  className,
  style,
  viewBox = "0 0 98 98",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="noun_Mail_1058930" fillRule="nonzero">
        <path
          d="M49,0 C21.9,0 0,21.9 0,49 C0,76.1 21.9,98 49,98 C76.1,98 98,76.1 98,49 C98,21.9 76.1,0 49,0 Z"
          id="Path"
          fill="currentColor"
        ></path>
        <path
          d="M76,68.8 C76,69.1 75.8,69.3 75.5,69.3 L22.5,69.3 C22.2,69.3 22,69.1 22,68.8 L22,32 C22,31.6 22.5,31.4 22.8,31.6 L48.7,53.1 C48.9,53.2 49.1,53.2 49.3,53.1 L75.2,31.6 C75.5,31.4 76,31.6 76,32 L76,68.8 Z"
          id="Path"
          fill="#000"
        ></path>
        <path
          d="M22.5,28.7 L75.4,28.7 C75.8,28.7 76,29.3 75.7,29.5 L49.3,51.6 C49.1,51.7 48.9,51.7 48.7,51.6 L22.2,29.5 C21.9,29.3 22.1,28.7 22.5,28.7 Z"
          id="Path"
          fill="#000"
        ></path>
      </g>
    </g>
  </svg>
);

const Back = ({
  width = 12,
  height = 10,
  className,
  style,
  viewBox = "0 -2 12 12",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.389 9.147V6.53H8.57a3.14 3.14 0 002.3-.95c.636-.633.954-1.399.954-2.297a3.13 3.13 0 00-.95-2.297 3.13 3.13 0 00-2.297-.95H7.436v1.183h1.141c.574 0 1.062.2 1.463.601.401.401.602.889.602 1.463 0 .57-.202 1.056-.605 1.46a1.997 1.997 0 01-1.467.605H3.39V2.735L.183 5.941l3.206 3.206z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const Lightning = ({
  width = 9,
  height = 13,
  className,
  style,
  viewBox = "0 0 9 13",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.89 13l2.945-2.1-.65-.915-1.618 1.16 1.591-9.51L1.944 5.38 2.735.704 1.33.44.002 8.29l6.214-3.745-1.072 6.363-1.16-1.626-.906.65 2.1 2.954z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const Heart = ({
  width = 16,
  height = 17,
  className,
  style,
  viewBox = "0 0 16 17",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16.17c.432-.45.432-.45 2.34-2.466 1.764-1.854 2.916-3.348 3.816-4.95.774-1.368 1.098-2.502 1.098-3.852 0-1.728-.576-3.042-1.674-3.798A3.83 3.83 0 0011.438.42c-.648 0-1.404.18-1.836.414-.666.378-1.188 1.188-1.35 2.07-.09.54-.144.648-.252.648-.108 0-.126-.054-.252-.648-.144-.81-.612-1.566-1.224-1.998C6.056.582 5.48.42 4.706.42c-1.35 0-2.34.486-3.06 1.494-.558.792-.9 1.908-.9 2.952 0 1.764.882 3.852 2.646 6.174C4.58 12.624 4.904 12.984 8 16.17z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const ExpandArrow = ({
  width = 41,
  height = 22,
  className,
  style,
  viewBox = "0 0 41 21",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M40.799 1L21 20.799 1.201 1"
      stroke="currentColor"
      fillRule="evenodd"
      fill="none"
    />
  </svg>
);

const Frown = ({
  width = 29,
  height = 29,
  className,
  style,
  viewBox = "0 0 29 29",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.438 28.156c3.796 0 7.03-1.328 9.703-3.984 2.656-2.672 3.984-5.89 3.984-9.656 0-3.797-1.328-7.032-3.984-9.704-2.672-2.687-5.907-4.03-9.703-4.03-3.766 0-6.985 1.343-9.657 4.03C2.11 7.485.773 10.72.773 14.517c0 3.765 1.336 6.984 4.008 9.656 2.672 2.656 5.89 3.984 9.657 3.984zm0-2.718c-3 0-5.563-1.079-7.688-3.235-2.14-2.14-3.21-4.703-3.21-7.687 0-3.047 1.07-5.633 3.21-7.758 2.125-2.125 4.688-3.188 7.688-3.188 3.015 0 5.593 1.063 7.734 3.188 2.14 2.125 3.21 4.71 3.21 7.758 0 2.984-1.07 5.546-3.21 7.687-2.14 2.156-4.719 3.235-7.735 3.235zm4.43-13.008c1.39-.063 2.116-.805 2.179-2.227-.063-1.344-.742-2.07-2.04-2.18-1.421.079-2.163.829-2.226 2.25.047 1.375.742 2.094 2.086 2.157zm-8.837 0c1.39-.063 2.125-.805 2.203-2.227-.078-1.344-.757-2.07-2.039-2.18-1.422.079-2.172.829-2.25 2.25.063 1.375.758 2.094 2.086 2.157zm-.82 8.86c.953-3.188 2.71-4.704 5.273-4.548 2.313-.14 4.079 1.367 5.297 4.524h1.242c-.234-4.172-2.382-6.399-6.445-6.68-4.187.219-6.398 2.453-6.633 6.703h1.266z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const Close = ({
  width = 30,
  height = 30,
  className,
  style,
  viewBox = "0 0 30 30",
}) => (
  <svg
    className={`${className}`}
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1px"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g id="Artboard" stroke="currentColor">
        <line
          x1="-2.23207142e-15"
          y1="-2.23207142e-15"
          x2="30"
          y2="30"
          id="Line"
        ></line>
        <line
          x1="-2.23207142e-15"
          y1="-2.23207142e-15"
          x2="30"
          y2="30"
          id="Line-Copy"
          transform="translate(15.000000, 15.000000) scale(-1, 1) translate(-15.000000, -15.000000) "
        ></line>
      </g>
    </g>
  </svg>
);

const icons = {
  back: Back,
  box: Box,
  ticket: Ticket,
  close: Close,
  email: Email,
  facebook: Facebook,
  frown: Frown,
  heart: Heart,
  instagram: Instagram,
  expandArrow: ExpandArrow,
  lightning: Lightning,
  logo: Logo,
};

export default (props) => {
  return <Fragment>{icons[props.name](props)}</Fragment>;
};
