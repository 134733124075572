import React from "react";

import Button from "./Button";
import Icon from "./Icon";
import { useKeyPress } from "../hooks/useKeyPress";
import { CSSTransition } from "react-transition-group";

const Modal = ({ open, close, children, onExited }) => {
  useKeyPress("Escape", close);
  if (open) {
    document.body.classList.add("noScroll");
  } else {
    document.body.classList.remove("noScroll");
  }
  return (
    <CSSTransition
      classNames="modalTransition"
      in={open}
      timeout={200}
      onExited={onExited}
    >
      <div className="modal">
        <div className="modal-wrapper">
          <div className="modal-content-wrapper">
            <div className="modal-content">
              <Button
                className="button--clean modal-bg"
                onClick={close}
              ></Button>
              <div className="modal-content-children padding-8 md_padding-16">
                {children}
                <Button className="button--clean modal-close" onClick={close}>
                  <div className="flex flex-align--center flex-justify--center">
                    <Icon name="close" className="c-navy" />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
